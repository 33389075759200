import {Component, HostListener, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {ProductyoukadoService} from '../../shared/services/productyoukado.service';
import {BrandService} from '../../shared/services/brand.service';
import {DeliverycountryService} from '../../shared/services/deliverycountry.service';
import {
    BrandYoukadoDtoInterface,
    CatalogueDtoInterface, CategoryToEnseigneDtoInterface,
    DeliveryCountryDtoInterface, EnseigneDtoInterface,
    LanguageDtoInterface,
    MotifsDtoInterface, ProductAndEnseigneDtoInterface,
    ProductSupplierDtoInterface,
    ProductYoukadoDtoInterface, PropertyDtoInterface,
    TranslationProductYoukadoDtoInterface
} from '../../shared/interfaces/models/rest';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {NgbCalendar, NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {ModalService} from 'src/app/shared/services';
import {DateUtils} from 'src/app/shared/utils/date.utils';
import {
    ProductFormvalidationInterface
} from "src/app/shared/interfaces/formvalidation/product/product-formvalidation.interface";
import {ProductUtils} from "src/app/shared/utils/product.utils";
import {ProductDetailsComponent} from "src/app/components/product/product-details/product-details.component";
import {LanguageService} from "src/app/shared/services/language.service";
import {MotifsService} from 'src/app/shared/services/motifs.service';
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";
import {combineLatest} from "rxjs/internal/observable/combineLatest";
import {KeycloakService} from 'keycloak-angular';
import {Subscription} from "rxjs";
import {
    ProductDescriptionsComponent
} from "src/app/components/product/product-descriptions/product-descriptions.component";
import {HttpParams} from "@angular/common/http";
import {CatalogService} from "src/app/shared/services/catalog.service";
import {PricehistoryComponent} from "src/app/components/pricehistory/pricehistory.component";

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})

export class ProductComponent extends BaseComponents implements OnInit {

    @ViewChild('tabs')
    private tabs: NgbTabset;

    @ViewChild('descriptions') descriptions: ProductDescriptionsComponent;

    @ViewChild(PricehistoryComponent) pricehistoryComponent: PricehistoryComponent;

    productYoukado: ProductYoukadoDtoInterface;
    productYoukadoCopy: ProductYoukadoDtoInterface;
    indexDescription: number = -1;
    catalogues: CatalogueDtoInterface[] = []
    catalogue: CatalogueDtoInterface;
    deliveryCountry: DeliveryCountryDtoInterface;
    deliveryCountries: DeliveryCountryDtoInterface[];
    brandsYoukado: BrandYoukadoDtoInterface[];
    productSuppliersCopy: ProductSupplierDtoInterface[];
    validationData: ProductFormvalidationInterface;
    alertError: boolean = false;
    alertSuccess: boolean = false;
    alertErrorEAN: boolean = false;
    title: string = null;
    dropdownSettingsDeliveryCountries: any = {};
    selectedDeliveryCountries: any[] = [];
    availableLanguages: LanguageDtoInterface[] = [];
    allMotifs: MotifsDtoInterface[];
    bestProductSupplier: ProductSupplierDtoInterface;
    isSlideMenu: boolean = false;
    savingButtonsNumber: number;
    hasAccess: boolean;
    private mySubscription: Subscription;
    eanCopy: any;
    showMessageEanError: boolean = false;
    showMessageEanSuccess: boolean = false;
    showLoadingEan: boolean = false;
    addProduct: boolean = false;

    constructor(
        protected router: Router,
        private brandYoukadoService: BrandService,
        private calendar: NgbCalendar,
        private modalService: ModalService,
        private productService: ProductyoukadoService,
        protected languageService: LanguageService,
        protected catalogService: CatalogService,
        private route: ActivatedRoute,
        private motifsService: MotifsService,
        private deliveryCountryService: DeliverycountryService,
        protected injector: Injector,
        private keycloakService: KeycloakService) {
        super(injector);
        this.productYoukado = {} as ProductYoukadoDtoInterface;
        this.validationData = {
            validationDataDescriptions: [],
            productType: false,
            dutyFreeSalePrice: false,
            descriptions: false,
            images: false,
            productsSupplier: false,
            brand: false,
            categories: false,
            ranges: false,
            alertImage: false,
            productSupplierKo: false,
            externalId: ''
        }
        this.translateService.onLangChange.subscribe(lang => {
            this.title = ProductUtils.getBestTitleForProduct(this.productYoukado, this.translateService.currentLang);
        })
        this.getScreenDimensions();
    }

    ngOnInit() {

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        this.mySubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
            }
        });


        this.hasAccess = this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_DIRECTION_GENERALE')
            || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_SUPER_ADMIN')
            || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_ACHATS');

        this.dropdownSettingsDeliveryCountries = {
            enableCheckAll: true,
            allowSearchFilter: true,
            textField: "label",
            idField: "code",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };

        this.availableLanguages = [];
        this.commondataService.showLoader(true);

        if (this.route.snapshot.params.id == undefined) {
            this.addProduct = true;
            this.subscriptions.push(combineLatest([
                this.brandYoukadoService.list(),
                this.languageService.list(),
            ]).subscribe(results => {
                this.brandsYoukado = results[0];
                this.availableLanguages = results[1];
                this.getLanguages();
                this.commondataService.showLoader(false);
            }, error => {
                this.dealWithStandardError(error);
                this.commondataService.showLoader(false);
            }));
        } else {

            this.subscriptions.push(combineLatest([
                this.brandYoukadoService.list(),
                this.languageService.list(),
                this.productService.get(this.route.snapshot.params.id)
            ]).subscribe(results => {
                this.productYoukado = results[2];
                if (this.productYoukado.idProductYoukado) {
                    this.brandsYoukado = results[0];
                    this.availableLanguages = results[1];
                    this.productYoukado.enseignesSelected = [];
                    this.productYoukado.isDataLoad = false;
                    this.productYoukado.isDataLoadProp = false;
                    this.productYoukado.enseignesProperties = new Map();
                    this.productYoukado.showEnseigne = new Map();
                    let productAndEnseigne = {} as ProductAndEnseigneDtoInterface;
                    productAndEnseigne.idProduct = this.productYoukado.idProductYoukado ? this.productYoukado.idProductYoukado : 0;
                    this.productService.getEnseignesInclusion(productAndEnseigne).subscribe(
                        (response) => {

                            this.productYoukado.enseignesSelected = response;
                            this.productYoukado.isDataLoad = true;
                            for (let enseignesSelected of this.productYoukado.enseignesSelected) {
                                enseignesSelected.isDataLoadProp = false;
                            }
                            this.getEnseigneProperties(this.productYoukado.enseignesSelected);
                        },
                        (error) => {
                            console.log('Error : ' + JSON.stringify(error));
                        }
                    );

                    this.productSuppliersCopy = JSON.parse(JSON.stringify(this.productYoukado.productSuppliers));
                    this.productYoukado.productSuppliers = this.productYoukado.productSuppliers.filter(s => s.parentProductSuppliers == null);
                    if (typeof this.productYoukado.catalogues == 'undefined' || this.productYoukado.catalogues == null || this.productYoukado.catalogues.length === 0) {
                        this.productYoukado.catalogues = [{} as CatalogueDtoInterface];
                    } else {
                        this.sortDeliveryCountries();
                    }
                    this.getDeliveryCountries();
                    this.getLanguages();
                    ProductUtils.sortProductSuppliers(this.productYoukado, this.deliveryCountry);
                    this.title = ProductUtils.getBestTitleForProduct(this.productYoukado, this.translateService.currentLang);
                    DateUtils.parseCatalogueInterfaceDate(this.productYoukado.catalogues);


                    this.getForcedBestSupplier();
                    if (this.productYoukado.ean) {
                        this.eanCopy = JSON.parse(JSON.stringify(this.productYoukado.ean));
                    }
                }
                this.commondataService.showLoader(false);

            }, error => {
                this.dealWithStandardError(error);
                this.commondataService.showLoader(false);
            }));
        }

    }


    getEnseigneProperties(enseignes: EnseigneDtoInterface[]) {
        this.productYoukado.enseignesProperties = new Map();
        if (enseignes != null && enseignes.length > 0) {
            for (let enseigne of enseignes) {
                let productAndEnseigne = {} as ProductAndEnseigneDtoInterface;
                productAndEnseigne.idEnseigne = enseigne.id_ext_enseigne;
                productAndEnseigne.idProduct = this.productYoukado.idProductYoukado ? this.productYoukado.idProductYoukado : 0;
                this.commondataService.showLoader(true);
                this.productService.getProperties(productAndEnseigne).subscribe(
                    (response) => {
                        if (!enseigne.isDataLoadProp && enseigne.isDataLoadProp != undefined) {
                            this.productYoukado.enseignesProperties.set(enseigne.nomEnseigne, response);
                            enseigne.isDataLoadProp = true;
                        }
                        this.buildProductToEnseigne();
                        this.commondataService.showLoader(false);
                    },
                    (error) => {
                        this.commondataService.showLoader(false);
                        console.log('Error : ' + JSON.stringify(error));
                    }
                );
            }
        }
    }

    buildProductToEnseigne() {
        this.productYoukado.productToEnseigne = [];
        this.productYoukado.showEnseigne = new Map();
        if (this.productYoukado.enseignesProperties != null) {
            this.productYoukado.enseignesProperties.forEach((propertyDtoEnseigne: PropertyDtoInterface[], key: string) => {
                let pushProductToEnseigne: Boolean = true;
                this.productYoukado.showEnseigne.set(key, false);
                for (let propertyDto of propertyDtoEnseigne) {
                    if (propertyDto.selectedPropertyValues != null && propertyDto.selectedPropertyValues.length > 0) {
                        this.productYoukado.showEnseigne.set(key, true);
                    }
                    if (propertyDto.selectedPropertyValues != null && propertyDto.selectedPropertyValues.length > 0) {


                        for (let value of propertyDto.selectedPropertyValues) {
                            if (value != null) {
                                const productToEnseigne = {} as CategoryToEnseigneDtoInterface;
                                productToEnseigne.nomEnseigne = key;
                                productToEnseigne.idPropriete = propertyDto.propertyId;
                                productToEnseigne.valeurPropriete = value.label;
                                let propertyValueDto = propertyDto.propertyValues.find(p => p.label != null && p.label == value.label);
                                if (propertyValueDto != null && propertyValueDto.value != null && propertyValueDto.value.trim() != "") {
                                    productToEnseigne.valeurPropriete = propertyValueDto.value;
                                }
                                if (this.productYoukado.productToEnseigne == null) {
                                    this.productYoukado.productToEnseigne = [];
                                }
                                this.productYoukado.productToEnseigne.push(productToEnseigne);
                                pushProductToEnseigne = false;
                            }
                        }
                    }
                }

                if (pushProductToEnseigne) {
                    const productToEnseigne = {} as CategoryToEnseigneDtoInterface;
                    productToEnseigne.nomEnseigne = key;
                    this.productYoukado.productToEnseigne.push(productToEnseigne);
                }

            });
        }

    }

    ngAfterViewChecked() {
        /* Dynamical saving buttons placement */
        this.savingButtonsNumber = document.getElementsByClassName('saving-button').length;
        if (document.getElementById('savingButtons')) {
            let marginPosition = 0;
            if (this.savingButtonsNumber > 3) {
                if (this.isSlideMenu) {
                    marginPosition = this.screenWidth / 90;
                } else {
                    if (this.screenWidth < 1600) {
                        marginPosition = this.screenWidth / 130;
                    } else {
                        marginPosition = this.screenWidth / 100;
                    }
                }
            } else {
                if (this.isSlideMenu) {
                    marginPosition = this.screenWidth / 70;
                } else {
                    if (this.screenWidth < 1600) {
                        marginPosition = this.screenWidth / 100;
                    } else {
                        marginPosition = this.screenWidth / 80;
                    }
                }
            }

            document.getElementById('savingButtons').style.marginLeft = marginPosition.toString() + "%";
        }
    }

    changeTab() {
        if (this.descriptions) {
            this.descriptions.changeTab();
        }
    }

    screenHeight: number;
    screenWidth: number;

    @HostListener('window:resize', ['$event'])
    getScreenDimensions(event?: any) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
    }

    openExternal() {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(
                ["/product/" + this.productYoukadoCopy.externalId]
            )
        );
        window.open('/marketplace/#' + url, '_blank');
    }

    checkEanExiste() {
        this.showLoadingEan = true;
        this.showMessageEanSuccess = false;
        this.showMessageEanError = false;

        let httpParams = new HttpParams();
        httpParams = httpParams.append("ean", this.productYoukado.ean);

        this.catalogService.search(httpParams, {pagination: {page: 0, size: 99999}}).subscribe(
            (response) => {
                if (response.content.length == 0) {
                    this.showMessageEanSuccess = true;
                    this.showMessageEanError = false;
                } else {
                    this.showMessageEanSuccess = false;
                    this.showMessageEanError = true;
                    this.productYoukadoCopy = response.content[0] as any as ProductYoukadoDtoInterface;
                }
                this.showLoadingEan = false;
            },
            (error) => {
                this.showLoadingEan = false;
                console.log('Error : ' + error);
            }
        );

    }

    mergeEan() {
        this.commondataService.showLoader(true);
        this.dealWithStandardObservableSubscription(this.productService.mergeProduct(this.productYoukado), {
            success: response => {
                this.commondataService.showLoader(false);
                let productYoukado:ProductYoukadoDtoInterface = response;
                this.router.navigate(['/product/'+productYoukado.externalId]).then();
            },
            error: response => {

                console.log("error : " + JSON.stringify(response));
                this.commondataService.showLoader(false);
            },
        });

    }

    getForcedBestSupplier() {
        this.bestProductSupplier = this.productYoukado.productSuppliers.find(ps => ps.deliveryCountryCode == this.deliveryCountry.code && ps.forced);
        if (this.bestProductSupplier == null) {
            this.bestProductSupplier = this.productYoukado.productSuppliers.find(ps => ps.deliveryCountryCode == this.deliveryCountry.code && ps.isBestChoice);
            if (this.bestProductSupplier == null) {
                this.bestProductSupplier = this.productYoukado.productSuppliers.find(ps => ps.deliveryCountryCode == this.deliveryCountry.code);
            }
        }
    }

    getMotifs() {
        this.motifsService.getMotifs().subscribe(
            (response) => {
                this.allMotifs = response;
                //console.log("Motifs : " + JSON.stringify(response));
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
                this.dealWithStandardError(error);
            }
        );
    }

    calculMargin() {
        if (typeof this.productYoukado.productSuppliers != 'undefined' && this.productYoukado.productSuppliers != null && this.productYoukado.productSuppliers.length !== 0) {
            for (let productSupplier of this.productYoukado.productSuppliers) {
                const catalogue = this.productYoukado.catalogues.find((c: CatalogueDtoInterface) => c.deliveryCountriesCatalogue.code == productSupplier.deliveryCountryProductSupplier.code);
                productSupplier.margin = ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, productSupplier.dutyFreeTotalBuyingPrice);
            }
        }
    }

    sortDeliveryCountries() {
        let cataloguesFr = this.productYoukado.catalogues.filter(c => c.deliveryCountriesCatalogue.code == 'FR');
        let catalogues = this.productYoukado.catalogues.filter(c => c.deliveryCountriesCatalogue.code != 'FR');
        this.productYoukado.catalogues = [];
        for (let catalogueFr of cataloguesFr) {
            this.productYoukado.catalogues.push(catalogueFr);
        }
        for (let catalogue of catalogues) {
            this.productYoukado.catalogues.push(catalogue);
        }
    }

    getLanguages() {
        if (this.productYoukado.translationProductYoukados == null || this.productYoukado.translationProductYoukados.length == 0) {
            this.productYoukado.translationProductYoukados = [];
        }

        const languagesProduct: string[] = this.productYoukado.translationProductYoukados.map((language: TranslationProductYoukadoDtoInterface) => language.language.code);

        const languagesDeliveryCountry: LanguageDtoInterface[] = this.availableLanguages.map((language: LanguageDtoInterface) => language);

        for (let language of languagesDeliveryCountry) {

            if (!languagesProduct.includes(language.code)) {
                const translationProductYoukadoInterface = {} as TranslationProductYoukadoDtoInterface;
                translationProductYoukadoInterface.language = language;
                this.productYoukado.translationProductYoukados.push(translationProductYoukadoInterface);
            }
        }

        this.productYoukado.translationProductYoukados.sort((a, b) => a.language.order < b.language.order ? -1 : 1);

    }

    validateForm(stateValid: string): boolean {
        if (this.tabs) {

            if (stateValid == "published" || stateValid == "save") {
                if (this.productYoukado.mediaToProductYoukados == undefined || this.productYoukado.mediaToProductYoukados.length == 0) {
                    this.validationData.images = true;
                    this.tabs.select("productImages");
                    return false;
                } else {
                    this.validationData.images = false;
                }

                if (this.productYoukado.productType == null || this.productYoukado.productType == "") {
                    this.validationData.productType = true;
                    this.tabs.select("productDetails");
                    return false;
                } else {
                    this.validationData.productType = false;
                }

                // if (this.productYoukado.brandYoukado == null || this.productYoukado.brandYoukado.label == "" || this.productYoukado.brandYoukado.label == undefined) {
                //     this.validationData.brand = true;
                //     this.tabs.select("productDetails");
                //     return false;
                // } else {
                //     this.validationData.brand = false;
                // }

                if (typeof this.productYoukado.catalogues == 'undefined' || this.productYoukado.catalogues.length === 0 || this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue.code == this.deliveryCountry.code).dutyFreeSalePrice == null) {
                    this.validationData.dutyFreeSalePrice = true;
                    this.tabs.select("suppliers");
                    return false;
                } else {
                    this.validationData.dutyFreeSalePrice = false;
                }

                if (this.deliveryCountry.code == 'FR' && this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue.code == this.deliveryCountry.code).margin < 0) {
                    this.modalService.open("alertMarginNegatif");
                    return false;
                } else {
                    this.validationData.dutyFreeSalePrice = false;
                }

                if (this.productYoukado.productSuppliers == undefined || this.productYoukado.productSuppliers.length == 0) {
                    this.validationData.productsSupplier = true;
                    this.tabs.select("suppliers");
                    return false;
                } else {
                    this.validationData.productsSupplier = false;
                }

                this.validationData.descriptions = false;
                let inValidForm = false;
                const labels = this.productYoukado.translationProductYoukados.filter(t => t.label != null && t.label.trim() != "");
                if (labels == null || labels.length == 0) {
                    inValidForm = true;
                    this.indexDescription = 0;
                    this.validationData.descriptions = true;
                    this.validationData.validationDataDescriptions[0] = {
                        productLabel: true,
                        description: false,
                    }
                    this.tabs.select("descriptions");
                } else {
                    this.validationData.validationDataDescriptions[0] = {
                        productLabel: false,
                        description: false,
                    }
                }

                if (inValidForm) {
                    return false;
                }

                const descriptions = this.productYoukado.translationProductYoukados.filter(t => t.description != null && t.description != "");
                if (descriptions == null) {

                    inValidForm = true;
                    this.indexDescription = 0;
                    this.validationData.descriptions = true;
                    this.validationData.validationDataDescriptions[0] = {
                        productLabel: false,
                        description: true,
                    }
                    this.tabs.select("descriptions");
                } else {

                    this.validationData.validationDataDescriptions[0] = {
                        productLabel: false,
                        description: false,
                    }
                }

                if (inValidForm) {
                    return false;
                }

                if (this.productYoukado.categoriesProductYoukado == undefined || this.productYoukado.categoriesProductYoukado.length == 0) {
                    this.validationData.categories = true;
                    this.tabs.select("categories");
                    return false;
                } else {
                    this.validationData.categories = false;
                }

                let alertSuppliersOutOfStock: boolean = true;
                for (let productsSupplier of this.productYoukado.productSuppliers) {
                    if (typeof this.productYoukado.productSuppliers == 'undefined' || this.productYoukado.productSuppliers.length === 0 || this.productYoukado.productSuppliers.find(c => c.deliveryCountryProductSupplier.code == this.deliveryCountry.code)?.stock > 0) {

                        alertSuppliersOutOfStock = false;

                    }
                }
                if (alertSuppliersOutOfStock) {
                    let state: string = this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue.code == this.deliveryCountry.code).state;
                    if (state == "published" || stateValid == "published") {
                        this.modalService.open("alertSuppliersOutOfStock");
                        return false;
                    }
                }
            }
        }
        return true;
    }

    redirectSuppliersTab() {
        this.tabs.select("suppliers");
        this.closeModal("alertSuppliersOutOfStock");
    }

    redirectMarginNegatif() {
        this.tabs.select("suppliers");
        this.closeModal("alertMarginNegatif");
    }

    updateCatalog(state: string) {
        for (let catalogue of this.productYoukado.catalogues) {
            // catalogue.productYoukadoCatalogue = null;
            if (state != "save") {
                if (catalogue.deliveryCountriesCatalogue.code == this.deliveryCountry.code) {
                    catalogue.state = state;
                }
            }
            if (this.stateSave == "declined" || this.stateSave == "unpublished") {
                if (catalogue.deliveryCountriesCatalogue.code == this.deliveryCountry.code) {
                    catalogue.state = this.stateSave;
                }
            }
            if (catalogue.deliveryCountriesCatalogue.code == this.deliveryCountry.code) {
                //@ts-ignore
                catalogue.editionDate = this.calendar.getToday();
            }
        }
    }

    currentCatalogueIndex: number;
    inputReason: string;
    stateSave: string;

    declined(state: string) {
        this.getMotifs();
        this.stateSave = state;
        this.modalService.open("declinedReason");
    }

    getDeliveryCountries() {
        this.catalogues = this.productYoukado.catalogues.map(e => e['deliveryCountriesCatalogue'].code)
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter((e) => this.productYoukado.catalogues[e]).map(e => this.productYoukado.catalogues[e]);
        this.deliveryCountry = this.productYoukado.catalogues[0].deliveryCountriesCatalogue;
        this.catalogue = this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue == this.deliveryCountry);
        this.currentCatalogueIndex = this.productYoukado.catalogues.findIndex(c => c.deliveryCountriesCatalogue == this.deliveryCountry);
    }

    changeDeliveryCountry(deliveryCountry: DeliveryCountryDtoInterface) {
        this.deliveryCountry = deliveryCountry;
        //this.getLanguages();
        ProductUtils.sortProductSuppliers(this.productYoukado, this.deliveryCountry);
        this.currentCatalogueIndex = this.productYoukado.catalogues.findIndex(c => c.deliveryCountriesCatalogue == this.deliveryCountry);
        this.catalogue = this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue == this.deliveryCountry);
        if (this.pricehistoryComponent)
            this.pricehistoryComponent.search();

        this.getForcedBestSupplier();
    }

    onSubmit(form: NgForm) {
        if (form.valid) {
        }
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    compareFn(option1: any, option2: any): boolean {
        if (option1 && option2) {
            return option1.code === option2.code;
        }
        return false;
    }

    getAllDeliveryCountries() {
        this.selectedDeliveryCountries = [];
        this.deliveryCountryService.getDeliveryCountries().subscribe(
            (response) => {
                this.deliveryCountries = response;
                if (this.productYoukado.catalogues != null) {
                    let deliveryCountries = this.productYoukado.catalogues.map((catalog: CatalogueDtoInterface) => catalog.deliveryCountriesCatalogue.code);
                    this.deliveryCountries = this.deliveryCountries.filter(d => !deliveryCountries.includes(d.code));
                }

                this.commondataService.showLoader(false);
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
                this.dealWithStandardError(error);
            }
        );
    }

    addDeliveryCountryModal() {
        this.getAllDeliveryCountries();
        this.modalService.open("deliveryCountriesAdd");
    }

    addDeliveryCountry(form: NgForm) {
        if (form.valid) {
            for (let deliveryCountry of this.selectedDeliveryCountries) {
                let deliveryCountryToAdd = this.deliveryCountries.find(d => d.code == deliveryCountry.code);
                const catalogue = {} as CatalogueDtoInterface;
                catalogue.state = "new";
                catalogue.deliveryCountriesCatalogue = deliveryCountryToAdd;
                if (this.productYoukado.catalogues == null || this.productYoukado.catalogues.length == 0) {
                    this.productYoukado.catalogues = [];
                }
                this.productYoukado.catalogues.push(catalogue);
                this.deliveryCountry = catalogue.deliveryCountriesCatalogue;
                //this.getLanguages();

                if (this.productYoukado.code == null) {
                    this.productService.getNextValSequence().subscribe(
                        (response) => {
                            this.productYoukado.code = "PY" + response;
                            this.commondataService.showLoader(false);
                        },
                        (error) => {
                            console.log('Error : ' + JSON.stringify(error));
                            this.commondataService.showLoader(false);
                            this.dealWithStandardError(error);
                        }
                    );
                }

                this.catalogue = catalogue;

            }


            this.modalService.close("deliveryCountriesAdd");
        }
    }

    // If the EAN posted already exist in the db, a button will be displayed with the role to redirect
    // the user to the existing product with the submitted ean
    goToExistingProduct() {
        this.productYoukado.code = null;
        this.router.navigate(['/products'], {queryParams: {search: JSON.stringify(this.productYoukado)}}).then();
    }

    save(state: string) {
        if (this.validateForm(state)) {
             if (this.catalogue.motif == 'autre')  this.catalogue.motif=this.inputReason;
            const catalog = this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue.code == this.deliveryCountry.code);
            const stateBefore = catalog.state;

            this.commondataService.showLoader(true);

            this.updateCatalog(state);

            this.productYoukado.mediaToProductYoukados.forEach(function (media, index) {
                media.priority = index;
            });

            DateUtils.formatCatalogueInterfaceDate(this.productYoukado.catalogues);
            this.productYoukado.stateActif = state;
            if (!this.productYoukado.brandYoukado || !this.productYoukado.brandYoukado.label) {
                this.productYoukado.brandYoukado = null;
            }
            this.dealWithStandardObservableSubscription(this.productService.post(this.productYoukado), {
                success: response => {
                    this.productYoukado = response;
                    DateUtils.parseCatalogueInterfaceDate(this.productYoukado.catalogues);
                    this.stateSave = "";
                    ProductUtils.sortProductSuppliers(this.productYoukado, this.deliveryCountry);
                    this.productYoukado.productSuppliers = this.productYoukado.productSuppliers.filter(s => s.parentProductSuppliers == null);
                    this.modalService.close("declinedReason");
                    this.catalogue = this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue.code == this.deliveryCountry.code);
                    this.sortDeliveryCountries();
                    this.calculMargin();
                    this.productYoukado.translationProductYoukados.sort((a, b) => a.language.order < b.language.order ? -1 : 1);
                    if (this.productYoukado.ean) {
                        this.eanCopy = JSON.parse(JSON.stringify(this.productYoukado.ean));
                    }
                    let productAndEnseigne = {} as ProductAndEnseigneDtoInterface;
                    productAndEnseigne.idProduct = this.productYoukado.idProductYoukado ? this.productYoukado.idProductYoukado : 0;
                    this.productService.getEnseignesInclusion(productAndEnseigne).subscribe(
                        (response) => {

                            this.productYoukado.enseignesSelected = response;
                            this.productYoukado.isDataLoad = true;
                            for (let enseignesSelected of this.productYoukado.enseignesSelected) {
                                enseignesSelected.isDataLoadProp = false;
                            }
                            this.getEnseigneProperties(this.productYoukado.enseignesSelected);
                        },
                        (error) => {
                            console.log('Error : ' + JSON.stringify(error));
                        }
                    );
                },
                error: response => {
                    this.commondataService.showLoader(false);
                    console.log(response);
                    if (response != null && response.error != null && response.error.error == null && response.error.externalId == null && response.error.includes("koImage")) {
                        this.validationData.alertImage = true;
                        this.tabs.select("productImages");
                    } else if (response != null && response.error != null && response.error.error == null && response.error.externalId != null) {
                        console.log(response.error.externalId);
                        this.validationData.productSupplierKo = true;
                        this.validationData.externalId = response.error.externalId;
                        this.tabs.select("suppliers");
                    }

                    for (let catalogue of this.productYoukado.catalogues) {
                        if (catalogue.deliveryCountriesCatalogue.code == this.deliveryCountry.code) {
                            catalogue.state = stateBefore;
                        }

                    }
                    DateUtils.parseCatalogueInterfaceDate(this.productYoukado.catalogues);
                    this.modalService.close("declinedReason");
                },
            });
        }
    }

    beforeChange($event: NgbTabChangeEvent) {

        if ($event.nextId === 'descriptions') {
            this.changeTab();
        }
    }
}
