<div class="card card-statistics mb-30">
    <div class="card-body">
        <h5 class="card-title">{{'components.product.tabs.details.product-infos' | translate}}</h5>
        <div class="form-row">
            <div class="col-md-4 mb-3">
                <label class="control-label" for="ean">{{'components.product.tabs.details.ean' | translate}}</label>
                <span (click)="checkEan()" *ngIf="productYoukado.ean != null && productYoukado.ean != '' && productYoukado.ean != eanCopy" class="btn btn-secondary"
                      style="margin-left: 14px;margin-bottom: 3px;font-size: 10px;">
                    Cliquer ici pour Vérifier l'ean
                </span>
                <img *ngIf="showLoadingEan" style="width: 32px;margin-left: 10px;position: absolute;top: -7px;" src="assets/images/pre-loader/loader-01.svg">
                <i *ngIf="showMessageEanError" style="color:red;margin-left: 10px;font-size: 17px;" class="fa fa-times-circle"></i> <span *ngIf="showMessageEanError" style="color:red;margin-left: 5px">Ean existe déjà, cliquer
                    <a (click)="openExternalDetails()" style="color: #505094;text-decoration: underline;cursor:pointer;">ici</a> pour voir le produit</span>
                <br>
                <button (click)="mergeProduct()" *ngIf="showMessageEanError && productYoukado.idProductYoukado != null" class="btn btn-info button_space saving-button">Fusionner cette fiche produit avec cet ean</button>
                <i *ngIf="showMessageEanSuccess" style="color:green;margin-left: 10px;font-size: 17px;" class="fa fa-check-circle"></i> <span *ngIf="showMessageEanSuccess" style="color:green;margin-left: 5px">Ean valide</span>
                <div class="mb-2">
                    <input (ngModelChange)="changeEan()" [disabled]="eanCopy != null && eanCopy != ''" type="text" class="form-control" id="ean"
                           name="ean" [(ngModel)]="productYoukado.ean"
                           #ean="ngModel"/>
                </div>
            </div>

            <div class="col-md-4 mb-3">
                <label class="control-label" for="code">Code interne kalido</label>
                <div class="mb-2">
                    <input disabled type="text" class="form-control" id="code" name="code"
                           [(ngModel)]="productYoukado.code" #code="ngModel"/>
                </div>
            </div>

            <div class="col-md-4 mb-3">
                <label class="control-label" for="brand">{{'components.product.tabs.details.brand' | translate}}</label>
                <div class="mb-2">
                    <div style="width: 100%;" class="ng-autocomplete">
                        <ng-autocomplete id="brand" [data]="brandsYoukadoAutoComplete" [searchKeyword]="keyword"
                                         (inputChanged)='onChangeSearch($event)' [itemTemplate]="itemTemplate"
                                         [notFoundTemplate]="notFoundTemplate"
                                         [(ngModel)]="productYoukado.brandYoukado">
                        </ng-autocomplete>
<!--                        <em class="error help-block" *ngIf="validationData.brand">-->
<!--                            {{'components.product.tabs.details.required-message-brand'| translate}}-->
<!--                        </em>-->
                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.label"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div>Aucun résultat</div>
                        </ng-template>
                    </div>
                </div>
            </div>

            <!-- Product type -->
            <div class="col-md-4 mb-3">
                <label class="control-productType" for="productType">
                    {{'components.product.tabs.details.product-type' | translate}}<span style="color:red">*</span>
                </label>
                <div class="mb-2">
                    <select required #productType="ngModel" [(ngModel)]="productYoukado.productType"
                            (change)="onChangeType()" name="productType" id="productType"
                            class="custom-select custom-select-lg mb-3">
                        <option value=""></option>
                        <option value="Cadeau">{{'components.product.tabs.details.gift' | translate}}</option>
                        <option value="Service">{{'components.product.tabs.details.service' | translate}}</option>
                        <option value="Évènement">{{'components.product.tabs.details.event' | translate}}</option>
                        <option value="Avantage">{{'components.product.tabs.details.advantage' | translate}}</option>
                    </select>
                    <em class="error help-block" *ngIf="validationData.productType">
                        {{'components.product.tabs.details.required-message-productType'| translate}}
                    </em>
                </div>
            </div>

            <div *ngIf="productYoukado.productType=='Avantage'" class="col-md-4 mb-3">
                <label class="control-label" for="lienAvantage">
                    {{'components.product.tabs.details.link-advantage' | translate}}
                </label>
                <div class="mb-2">
                    <input type="text" class="form-control" id="lienAvantage" name="lienAvantage"
                           [(ngModel)]="productYoukado.lienAvantage" #lienAvantage="ngModel"/>
                </div>
            </div>

            <!-- Pictos -->
            <div class="col-md-4 mb-3">
                <label class="control-label" for="pictos">
                    {{'components.product.tabs.details.pictos' | translate}}
                    <img style="width: 25px;height:20px;"
                         src="assets/images/countries/{{deliveryCountry.code}}.jpg"
                         alt="Drapeau_{{deliveryCountry.code}}">
                </label>
                <div class="mb-2">
                    <ng-multiselect-dropdown [(ngModel)]="catalogue.pictos" [settings]="dropdownSettingsPictos"
                                             [data]="allPictos" [placeholder]="'Sélectionner un ou plusieurs pictos'"
                                             (onSelect)="onSelectPicto($event)"
                                              id="pictos"
                                             name="picto">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <div class="col-md-4 mb-3">
                <label class="control-label" for="code">Quantité minimale</label>
                <div class="mb-2">
                    <input type="text" class="form-control" id="quantiteMin" name="quantiteMin"
                           [(ngModel)]="productYoukado.quantiteMin" #code="ngModel"/>
                </div>
            </div>

            <!-- Checkboxes -->
            <div class="col-md-2 mb-3">
                <!-- Eco responsable -->
                <div class="remember-checkbox mb-20">
                    <input type="checkbox" class="form-control" name="ecoresponsable" id="ecoresponsable"
                           (click)="setEcoresponsable()" [checked]="this.productYoukado.ecoresponsable">
                    <label for="ecoresponsable">{{'components.product.tabs.details.ecoresponsable' | translate}}</label>
                </div>
            </div>
            <div class="col-md-2 mb-3">

                <!-- Montant libre -->
                <div class="remember-checkbox mb-20">
                    <input type="checkbox" class="form-control" name="montantlibre" id="montantlibre"
                           (click)="setMontantlibre()" [checked]="this.productYoukado.montantlibre">
                    <label for="montantlibre">{{'components.product.tabs.details.montantlibre' | translate}}</label>
                </div>

            </div>
            <div class="col-md-2 mb-3">
                <!-- Disable enseigne price -->
                <div class="remember-checkbox mb-20">
                    <input type="checkbox" [(ngModel)]="productYoukado.surDevis" class="form-control"
                           name="surDevis" id="surDevis">
                    <label for="surDevis">Sur devis</label>
                </div>
            </div>

            <div class="col-md-4 mb-3">
                <!-- Disable enseigne price -->
                <div class="remember-checkbox mb-20">
                    <input type="checkbox" [(ngModel)]="productYoukado.disableSalePriceEnseigne" class="form-control"
                           name="disableSalePriceEnseigne" id="disableSalePriceEnseigne">
                    <label for="disableSalePriceEnseigne">Désactiver tarification enseigne</label>
                </div>
            </div>


            <div *ngIf="(productYoukado && productYoukado.enseignesSelected && productYoukado.enseignesSelected.length == 0) || !productYoukado.idProductYoukado" class="col-md-4 mb-3">
                <label class="control-label">Spécifique aux enseignes</label>
                <div class="mb-2">
                    <ng-multiselect-dropdown id="enseignes" name="enseignese" [(ngModel)]="productYoukado.enseignesSelected"
                                             [placeholder]="'Sélectionner une enseigne'"
                                             [settings]="dropdownSettingsEnseignes"
                                             [data]="enseignes" style="padding: 0; border-width: 0;"
                                             (onSelect)="onSelectedEnseigne($event)"
                                             (onDeSelect)="onDeSelectedEnseigne($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>

        </div>

        <div *ngIf="productYoukado && productYoukado.enseignesSelected && productYoukado.enseignesSelected.length > 0" class="form-row">
            <ngb-tabset class="tab nav-center" style="width: 100%;">
                <ngb-tab title="Spécifique aux enseignes">
                    <ng-template ngbTabContent>

                        <div class="form-row">
                            <div class="col-md-4 mb-3">
                                <label class="control-label">Spécifique aux enseignes</label>
                                <div class="mb-2">
                                    <ng-multiselect-dropdown id="enseignes" name="enseignes"
                                                             [(ngModel)]="productYoukado.enseignesSelected"
                                                             [placeholder]="'Sélectionner une enseigne'"
                                                             [settings]="dropdownSettingsEnseignes" [data]="enseignes"
                                                             style="padding: 0; border-width: 0;"
                                                             (onSelect)="onSelectedEnseigne($event)"
                                                             (onDeSelect)="onDeSelectedEnseigne($event)">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Pour qui ?" *ngIf="productYoukado.enseignesProperties && productYoukado.enseignesProperties.size > 0">
                    <ng-template ngbTabContent>

                        <div class="accordion gray plus-icon round">
                            <div class="row">
                                <div *ngFor="let enseigne of productYoukado.enseignesProperties | keyvalue; let j = index"
                                     class="col-xl-3 mb-30">

                                    <div class="acd-group {{productYoukado && productYoukado.showEnseigne.get(enseigne.key) ? 'acd-active' :''}}"
                                         id="wrapDiv{{enseigne.key}}">

                                        <a href="javascript:void(0);" class="acd-heading"
                                           (click)='OpenAccordion("submenu".concat(enseigne.key),"wrapDiv".concat(enseigne.key))'>{{enseigne.key}}</a>
                                        <div id="submenu{{enseigne.key}}"
                                             class="acd-des {{productYoukado && productYoukado.showEnseigne.get(enseigne.key) ? ' show' :''}}">

                                            <div class="form-group">
                                                <ng-container *ngFor="let property of enseigne.value; let j = index">
                                                    <label class="control-label">
                                                        {{property.propertyLabel}}
                                                    </label>
                                                    <div class="mb-2">
                                                        <ng-multiselect-dropdown id="forWho_{{j}}" name="forWho"
                                                                                 [ngModelOptions]="{standalone: true}"
                                                                                 [(ngModel)]="property.selectedPropertyValues"
                                                                                 [placeholder]="'Sélectionner pour qui'"
                                                                                 [settings]="dropdownSettingsForWho"
                                                                                 [data]="property.propertyValues"
                                                                                 (onSelect)="buildProductToEnseigne()"
                                                                                 (onDeSelect)="buildProductToEnseigne()"
                                                                                 class="form-control form-control-lg mb-15"
                                                                                 style="padding: 0; border-width: 0;">
                                                        </ng-multiselect-dropdown>
                                                    </div>
                                                </ng-container>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>

<ng-container>
    <div>
        <div class="card card-statistics mb-30">
            <div class="card-body">
                <h5 class="card-title">{{'components.product.tabs.details.enrichment-dates' | translate}}</h5>
                <img class="flag-deliveryCountry" src="assets/images/countries/{{deliveryCountry.code}}.jpg">
                <div class="form-row">

                    <div class="col-md-4 mb-3">
                        <label class="control-label"
                               for="selectionDate">{{'components.product.tabs.details.selectionDate'
                            | translate}}</label>
                        <div class="mb-2">
                            <input disabled type="text" required class="form-control" id="selectionDate"
                                   name="selectionDate" [(ngModel)]="catalogue.selectionDate" #selectionDate="ngModel"/>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="control-label" for="editionDate">{{'components.product.tabs.details.editionDate'
                            | translate}}</label>
                        <div class="mb-2">
                            <input disabled type="text" required class="form-control" id="editionDate"
                                   name="editionDate" [(ngModel)]="catalogue.editionDate" #editionDate="ngModel"/>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="control-label"
                               for="publicationDate">{{'components.product.tabs.details.publicationDate'
                            | translate}}</label>
                        <div class="mb-2">
                            <input disabled type="text" required class="form-control" id="publicationDate"
                                   name="publicationDate" [(ngModel)]="catalogue.publicationDate"
                                   #publicationDate="ngModel"/>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="control-label"
                               for="depublicationDate">{{'components.product.tabs.details.depublicationDate'
                            | translate}}</label>
                        <div class="mb-2">
                            <input disabled type="text" required class="form-control" id="depublicationDate"
                                   name="depublicationDate" [(ngModel)]="catalogue.depublicationDate"
                                   #depublicationDate="ngModel"/>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="control-label" for="standByDate">{{'components.product.tabs.details.standByDate'
                            | translate}}</label>
                        <div class="mb-2">
                            <input disabled type="text" required class="form-control" id="standByDate"
                                   name="standByDate" [(ngModel)]="catalogue.standByDate" #standByDate="ngModel"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-statistics mb-30">
            <div class="card-body">
                <h5 class="card-title">{{'components.product.tabs.details.highlight-publication-unpublication-dates' |
                    translate}}</h5>
                <img class="flag-deliveryCountry" src="assets/images/countries/{{deliveryCountry.code}}.jpg">
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label class="control-depublicationStartDate" for="depublicationStartDate">
                            {{'components.product.tabs.details.date-start-publication'| translate}}
                        </label>
                        <div class="input-group">
                            <input id="depublicationStartDate" class="form-control" placeholder="AAAA-MM-JJ" name="d1"
                                   #c1="ngModel" [(ngModel)]="catalogue.depublicationStartDate" ngbDatepicker
                                   #d1="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-secondary" (click)="d1.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="control-depublicationEndDate" for="depublicationEndDate">
                            {{'components.product.tabs.details.date-end-publication'| translate}}
                        </label>
                        <div class="input-group">
                            <input id="depublicationEndDate" placeholder="AAAA-MM-JJ" class="form-control" name="dp2"
                                   [(ngModel)]="catalogue.depublicationEndDate" ngbDatepicker #d2="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-secondary" (click)="d2.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2 mb-3">
                        <div class="remember-checkbox mb-20">
                            <input [checked]="catalogue.dateBoostedStart != null || catalogue.dateBoostedEnd != null"
                                   (click)="showDateBoost()" type="checkbox" class="form-control" name="one" id="one">
                            <label for="one">{{'components.product.tabs.details.novelty' | translate}}</label>
                        </div>
                    </div>

                    <div *ngIf="isShowDateBoost" class="col-md-5 mb-3">
                        <label class="control-dateBoostedStart" for="dateBoostedStart">
                            {{'components.product.tabs.details.highlight-start-date'| translate}}
                        </label>
                        <div class="input-group">
                            <input id="dateBoostedStart" placeholder="AAAA-MM-JJ" class="form-control" name="dp3"
                                   [(ngModel)]="catalogue.dateBoostedStart" ngbDatepicker #d3="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-secondary" (click)="d3.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isShowDateBoost" class="col-md-5 mb-3">
                        <label class="control-dateBoostedEnd" for="dateBoostedEnd">
                            {{'components.product.tabs.details.highlight-end-date'| translate}}
                        </label>
                        <div class="input-group">
                            <input id="dateBoostedEnd" placeholder="AAAA-MM-JJ" class="form-control" name="dp4"
                                   [(ngModel)]="catalogue.dateBoostedEnd" ngbDatepicker #d4="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-secondary" (click)="d4.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>