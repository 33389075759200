import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {
    BrandDtoInterface,
    CategoryDtoInterface,
    EnseigneDtoInterface, PictosDtoInterface,
    ProductYoukadoDtoInterface,
    PropertyDtoInterface,
    RuleAndEnseigneDtoInterface,
    RuleDtoInterface,
    RuleToEnseigneDtoInterface,
    SupplierDtoInterface
} from "src/app/shared/interfaces/models/rest";
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";
import {CommonDataService} from "src/app/common-data.service";
import {ModalService} from "src/app/shared/services";
import {EnseignesService} from "src/app/shared/services/enseignes-service";
import {CategoryService} from "src/app/shared/services/category.service";
import {RuleService} from "src/app/shared/services/rule.service";
import {CategorytreeUtils} from "src/app/shared/utils/categorytree.utils";
import {ProductyoukadoService} from "src/app/shared/services/productyoukado.service";
import {SupplierService} from "src/app/shared/services/supplier.service";
import {BrandService} from "src/app/shared/services/brand.service";
import {MultiSelectComponent} from "ng-multiselect-dropdown";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {Subject} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {PaginationInterface} from "src/app/shared/interfaces/pagination.interface";
import {PaginationUtils} from "src/app/shared/utils/pagination.utils";
import {PaginationbarInterface} from "src/app/shared/interfaces/paginationbar.interface";
import {debounce} from "underscore";
import {SyncCatalogService} from "src/app/shared/services/sync-catalog.service";
import {KeycloakService} from 'keycloak-angular';
import {IDropdownSettings} from "ng-multiselect-dropdown/multiselect.model";
import {PictosService} from "src/app/shared/services/pictos.service";

@Component({
    selector: 'rules',
    templateUrl: './rule.component.html',
    styleUrls: ['./rule.component.css']
})
export class RuleComponent extends BaseComponents implements OnInit {

    //region Variables

    /* Searching rules criteria : name */
    searchRuleNameUpdate = new Subject<string>();
    searchRuleName: string;

    /* Searching rules criteria : enseigne */
    dropdownSettingsEnseignesSearch: any = {};
    dropdownSettingsEnseignes: any = {};
    allEnseignesList: EnseigneDtoInterface[];
    selectedEnseigne: EnseigneDtoInterface[] = [];
    identityRule: string;

    dropdownListTypeCategory: any[] = [
        {item_id: 'Personnelle', item_text: 'Loisir'},
        {item_id: 'Professionnelle', item_text: 'Professionnelle'},
    ];
    dropDownCategorySettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'item_id',
        textField: 'item_text',
        allowSearchFilter: false,
        enableCheckAll: false
    };
    currentTypeCategory: any[] = [];

    /* Searching rules criteria : type */
    dropdownSettingsRuleType: any = {};
    allRuleTypesList: any[] = [
        "categorie",
        "produit",
        "fournisseur",
        "marque",
        "range", //todo: remplacer par prix_de_vente
        "picto",
    ];
    // pictos
    dropdownSettingsPictos: any = {};
    allPictos: PictosDtoInterface[];
    selectedPictos: PictosDtoInterface[] = [];
    @ViewChild('ngPictoSelect') pictoSelect: MultiSelectComponent;

    selectedRuleType: any[] = [];

    /* Searching rules criteria : active */
    isRuleActive: boolean = true;

    /* Rules list displayed*/
    rulePageable: PaginationbarInterface;
    rulesResponse: any[] = [];
    displayedRules: any[] = [];

    /* Edit/Create rule */
    selectedRule: RuleDtoInterface;
    ruleType: string[] = [];
    valueIsMissing: boolean = false;
    existingRuleName: boolean = false;
    noRuleName: boolean = false;

    /* Rule forWho */
    dropdownSettingsForWho: any = {};
    enseignesProperties: Map<string, PropertyDtoInterface[]> = new Map();

    /* Categories */
    categories: CategoryDtoInterface;
    searchCriteriaCategories: { keywords?: string, main?: boolean, specific?: boolean, service?: boolean, animation?: boolean, type?: string  };
    formGroupSearch: UntypedFormGroup;
    selectedCategoriesInTree: any[] = [];
    categoriesToExclude: any[] = [];
    treeCatgeriesSelected: any[] = [];

    showEnseigne: Map<string, boolean> = new Map();

    /* Products */
    productToCheck: string;
    productsToExclude: ProductYoukadoDtoInterface[] = [];
    wrongCodeErrorMessage: boolean = false;
    codeAlreadyInListErrorMessage: boolean = false;
    duplicatedCodes: string[] = [];
    undefinedCodes: string[] = [];

    /* Suppliers */
    dropdownSettingsSuppliers: any = {};
    allSuppliers: SupplierDtoInterface[];
    selectedSuppliers: SupplierDtoInterface[] = [];
    @ViewChild('ngSupplierSelect') supplierSelect: MultiSelectComponent;

    /* Brands */
    dropdownSettingsBrands: any = {};
    allBrands: BrandDtoInterface[];
    selectedBrands: BrandDtoInterface[] = [];
    @ViewChild('ngBrandSelect') brandSelect: MultiSelectComponent;

    /* Ranges */
    selectedValueMin: number = 0;
    selectedValueMax: number = 0;
    rangesToExclude: { minimumValue: number; maximumValue: number }[] = [];
    minMoreThanMax: boolean = false;
    hasAccess: boolean = false;

    //endregion

    //region Constructor

    constructor(
        protected injector: Injector,
        private _commondata: CommonDataService,
        protected formBuilder: UntypedFormBuilder,
        protected modalService: ModalService,
        private enseignesService: EnseignesService,
        protected ruleService: RuleService,
        protected syncCatalogService: SyncCatalogService,
        protected categoryService: CategoryService,
        protected productYoukadoService: ProductyoukadoService,
        protected supplierService: SupplierService,
        protected brandService: BrandService,
        private keycloakService: KeycloakService,
        private pictosService: PictosService,
    ) {
        super(injector);
        this.searchCriteriaCategoryUpdate = debounce(this.searchCriteriaCategoryUpdate, 300);
        this.searchRuleNameUpdate.pipe(debounceTime(800), distinctUntilChanged()).subscribe(
            (value) => {
                this.getRulesList();
            }
        );
    }

    //endregion

    //region ngInit

    ngOnInit(): void {

        this._commondata.setExpandDiv('elements');
        this.hasAccess = this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_DIRECTION_GENERALE')
            || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_SUPER_ADMIN')
            || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_MARKETING');



        this.dropdownSettingsPictos = {
            enableCheckAll: false,
            allowSearchFilter: true,
            textField: "label",
            idField: "code",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        /* Set dropdown settings */
        this.dropdownSettingsEnseignesSearch = {
            enableCheckAll: false,
            singleSelection: true,
            allowSearchFilter: true,
            textField: "nomEnseigne",
            idField: "id_ext_enseigne",
            searchPlaceholderText: "Rechercher"
        };
        this.dropdownSettingsEnseignes = {
            enableCheckAll: false,
            singleSelection: false,
            allowSearchFilter: true,
            textField: "nomEnseigne",
            idField: "id_ext_enseigne",
            searchPlaceholderText: "Rechercher"
        };
        this.dropdownSettingsRuleType = {
            enableCheckAll: false,
            singleSelection: true,
            allowSearchFilter: true,
            textField: "type",
            idField: "id",
            searchPlaceholderText: "Rechercher"
        };
        this.dropdownSettingsForWho = {
            enableCheckAll: false,
            allowSearchFilter: true,
            textField: "label",
            idField: "label",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        this.dropdownSettingsSuppliers = {
            enableCheckAll: false,
            singleSelection: false,
            allowSearchFilter: true,
            textField: "label",
            idField: "code",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        this.dropdownSettingsBrands = {
            enableCheckAll: false,
            singleSelection: false,
            allowSearchFilter: true,
            textField: "label",
            idField: "idBrandYoukado",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };

        /* Get all enseignes list */
        this.commondataService.showLoader(true);
        this.enseignesService.getEnseignes().subscribe(
            (response) => {
                this.allEnseignesList = response;
                this.commondataService.showLoader(false);
            },
            (error) => {
                console.log(error);
                this.commondataService.showLoader(false);
            }
        );

        /* Get all Pictos list */
        this.commondataService.showLoader(true);
        this.pictosService.getPictos().subscribe(
            (response) => {
                this.allPictos = response;
                this.commondataService.showLoader(false);
            },
            (error) => {
                console.log(error);
                this.commondataService.showLoader(false);
            }
        );

    }

    //endregion

    //region Rule list display

    private performSearch(opt?: { search: HttpParams, pagination?: PaginationInterface }): void {
        if (this.searchRuleName || this.selectedEnseigne.length > 0 || this.selectedRuleType.length > 0) {
            this.commondataService.showLoader(true);
            this.ruleService.search(opt?.search, {pagination: opt?.pagination}).subscribe(
                (response) => {
                    this.rulePageable = PaginationUtils.getPagination(response);
                    this.rulesResponse = response.content;

                    this.displayedRules = this.rulesResponse;

                    //console.log("rules : "+JSON.stringify(this.displayedRules));
                    this.commondataService.showLoader(false);
                },
                (error) => {
                    this.dealWithStandardError(error);
                    this.commondataService.showLoader(false);
                });
        }

    }

    paginate(pagination: { page: number, size?: number }): void {
        pagination.size = 9;
        this.performSearch({search: this.getSearchParams(), pagination: pagination});
    }

    private getSearchParams(): HttpParams {
        let httpParams = new HttpParams();
        if (this.searchRuleName && this.searchRuleName.trim() != "") {
            httpParams = httpParams.append("name", this.searchRuleName);
        }
        if (this.selectedEnseigne && this.selectedEnseigne.length > 0) {
            httpParams = httpParams.append("idEnseigne", this.selectedEnseigne[0].id_ext_enseigne);
        }
        if (this.selectedRuleType && this.selectedRuleType.length > 0) {
            httpParams = httpParams.append("type", this.selectedRuleType[0]);
        }
        httpParams = httpParams.append("active", this.isRuleActive);
        httpParams = httpParams.append("action", "EXC");

        return httpParams;
    }

    getRulesList(activeButton?: string) {
        this.clearRulesList();

        if (activeButton) {
            this.isRuleActive = !this.isRuleActive;
        }

        this.performSearch({search: this.getSearchParams(), pagination: {page: 0, size: 9}});
    }

    clearRulesList() {
        this.displayedRules = [];
    }

    //endregion

    //region Rule edit

    editRule(rule: RuleDtoInterface) {
        rule.enseigne = rule.ruleDtos.map((rule: RuleDtoInterface) => rule.enseigneDto);

        this.loadCategories();
        this.getAllSuppliers();
        this.getAllBrands();

        this.selectedRule = JSON.parse(JSON.stringify(rule));

        this.selectedRule.enseigneActive = new Map<number, boolean>();
        for (let enseigne of this.selectedRule.enseigne) {
            this.selectedRule.enseigneActive.set(enseigne.id_ext_enseigne, this.selectedRule.active);
        }

        this.ruleType[0] = this.selectedRule.type;
        this.getEnseigneProperties();

        if (!this.formGroupSearch) {
            this.formGroupSearch = this.formBuilder.group({
                keywords: new UntypedFormControl(''),
                searchTypeCategory: new UntypedFormControl(''),
                searchMain: new UntypedFormControl(true),
                searchSpecific: new UntypedFormControl(false),
                searchService: new UntypedFormControl(false),
                searchAnimation: new UntypedFormControl(false),
                searchFilter: new UntypedFormControl('searchMain')
            });
        }

        this.formGroupSearch.value.searchTypeCategory = [];
        this.currentTypeCategory = [];
        if (this.selectedRule.value != null && this.selectedRule.value.trim() != "") {
            this.getRuleValues(this.selectedRule);
        }

        this.modalService.open("editModal");
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.resetValues(true);
        this.enseignesProperties.clear();
        this.selectedRule = null;
    }

    setActive() {
        console.log("rules : " + JSON.stringify(this.selectedRule));
    }

    resetValues(type?: any) {
        if (type) {
            this.ruleType = [];
            this.selectedRule.type = null;
        }
        this.formGroupSearch = this.formBuilder.group({
            keywords: new UntypedFormControl(''),
            searchTypeCategory: new UntypedFormControl(''),
            searchMain: new UntypedFormControl(true),
            searchSpecific: new UntypedFormControl(false),
            searchService: new UntypedFormControl(false),
            searchAnimation: new UntypedFormControl(false),
            searchFilter: new UntypedFormControl('searchMain')
        });
        this.searchCriteriaCategories = {
            keywords: this.formGroupSearch.value.keywords,
            main: this.formGroupSearch.value.searchMain,
            specific: this.formGroupSearch.value.searchSpecific,
            service: this.formGroupSearch.value.searchService,
            animation: this.formGroupSearch.value.searchAnimation,
            type: this.formGroupSearch.value.searchTypeCategory != null && this.formGroupSearch.value.searchTypeCategory.length > 0 ? this.formGroupSearch.value.searchTypeCategory[0].item_id : null,
        };
        this.categoriesToExclude = [];
        this.selectedCategoriesInTree = [];
        this.treeCatgeriesSelected = [];
        this.productToCheck = null;
        this.productsToExclude = [];
        this.wrongCodeErrorMessage = false;
        this.codeAlreadyInListErrorMessage = false;
        this.duplicatedCodes = [];
        this.undefinedCodes = [];
        this.selectedSuppliers = [];
        this.selectedBrands = [];
        this.selectedValueMin = 0;
        this.selectedValueMax = 0;
        this.rangesToExclude = [];
        this.minMoreThanMax = false;
        this.valueIsMissing = false;
        this.existingRuleName = false;
    }

    getRuleValues(rule: RuleDtoInterface) {
        this.identityRule = rule.value+"##"+this.selectedRule.type;
        this._commondata.showLoader(true);
        switch (rule.type) {
            case 'categorie':
                let regexCategories = new RegExp("[A-Za-z0-9]+", "g");
                let foundCategories = rule.value.trim().match(regexCategories);
                for (let c of foundCategories) {
                    this.categoryService.getCategoryByCode({categoryCode: c}).subscribe(
                        (response) => {
                            this.selectedCategoriesInTree.push(response.idCategory);
                            for (let translation of response.translationCategoriesCategory) {
                                if (translation.languageTranslationCategory.code == 'FR') {
                                    this.categoriesToExclude.push(response);
                                }
                            }
                            //this.loadCategories();
                        },
                        (error) => {
                            console.log(error);
                        }
                    )
                }
                this._commondata.showLoader(false);
                break;
            case 'produit':
                let regexProducts = new RegExp("[A-Za-z0-9]+", "g");
                let foundProducts = rule.value.trim().match(regexProducts);
                for (let p of foundProducts) {
                    this.productYoukadoService.getProductByCode({codeProduct: p}).subscribe(
                        (response) => {
                            if (!response) {
                                this.wrongCodeErrorMessage = true;
                                this.undefinedCodes.push(p);
                            } else {
                                this.productsToExclude.push(response);
                            }
                            this._commondata.showLoader(false);
                        },
                        (error) => {
                            console.log("error");
                            console.log(error);
                            this._commondata.showLoader(false);
                        }
                    )
                }
                break;
            case 'fournisseur':
                let regexSuppliers = new RegExp("[A-Za-z0-9]+", "g");
                let foundSuppliers = rule.value.trim().match(regexSuppliers);
                this.selectedSuppliers = [];
                for (let s of foundSuppliers) {
                    this._commondata.showLoader(true);
                    this.supplierService.getSupplierByCode({supplierCode: s}).subscribe(
                        (response) => {
                            this.selectedSuppliers.push(response);
                            this.supplierSelect.addSelected({id: response.code, text: response.label});
                            this._commondata.showLoader(false);
                        },
                        (error) => {
                            console.log("error");
                            console.log(error);
                            this._commondata.showLoader(false);
                        }
                    )
                }
                break;
            case 'picto':
                let foundPictos : any = rule.value.trim().split(";");
                this.selectedPictos = [];
                for (let p of foundPictos) {
                    if (p != '') {
                        this._commondata.showLoader(true);
                        this.pictosService.getPictoByCode({pictoCode: p}).subscribe(
                            (response) => {
                                this.selectedPictos.push(response);
                                this.pictoSelect.addSelected({id: response.code, text: response.label});
                                this._commondata.showLoader(false);
                            },
                            (error) => {
                                console.log("error");
                                console.log(error);
                                this._commondata.showLoader(false);
                            }
                        )
                    }
                }
                break;
            case 'marque':
                let regexBrands = new RegExp("[A-Za-z0-9&\-\'\+\\s*]+", "g");
                let foundBrands = rule.value.match(regexBrands);
                this.selectedBrands = [];
                for (let b of foundBrands) {
                    this._commondata.showLoader(true);
                    this.brandService.getBrandByLabel({brandLabel: b}).subscribe(
                        (response) => {
                            this.selectedBrands.push(response[0]);
                            this.brandSelect.addSelected({id: response[0].idBrandYoukado, text: response[0].label});
                            this._commondata.showLoader(false);
                        },
                        (error) => {
                            console.log("error");
                            console.log(error);
                            this._commondata.showLoader(false);
                        }
                    )
                }
                break;
            case 'range':
                let ranges: any = rule.value.trim().split(";");
                console.log(rule.value.trim());
                for (let i = 0; i < ranges.length; i++) {
                    if(ranges[i] != null && ranges[i] != "") {
                        let value: any = ranges[i].split("::")
                        this.rangesToExclude.push({
                            minimumValue: value[0],
                            maximumValue: value[1],
                        });
                    }

                }
                console.log(this.rangesToExclude);
                this._commondata.showLoader(false);
                break;
            default:
                this.modalService.open("editModal");
                this._commondata.showLoader(false);
                return;
        }
    }

    //endregion

    //region Rule create

    createRule() {
        this.identityRule = null;
        this.selectedRule = {} as RuleDtoInterface;

        /* Loading categories data */
        this.loadCategories();
        if (!this.formGroupSearch) {
            this.formGroupSearch = this.formBuilder.group({
                keywords: new UntypedFormControl(''),
                searchTypeCategory: new UntypedFormControl(''),
                searchMain: new UntypedFormControl(true),
                searchSpecific: new UntypedFormControl(false),
                searchService: new UntypedFormControl(false),
                searchAnimation: new UntypedFormControl(false),
            });
        }

        this.getAllSuppliers();
        this.getAllBrands();

        this.modalService.open("editModal");
    }

    //endregion

    //region Rule save

    saveRule() {
        this.valueIsMissing = false;
        this.existingRuleName = false;

        if (this.selectedRule.name == null || this.selectedRule.name.trim() == '') {
            this.noRuleName = true;
            return;
        }

        this.ruleService.getRulesName({ruleName: this.selectedRule.name}).subscribe(
            (response: any) => {
                if (response != null && response.length > 0 && !this.selectedRule.idRule) {
                    this.existingRuleName = true;
                } else {
                    this.selectedRule.type = this.ruleType[0];
                    this.buildRuleToEnseigne();

                    switch (this.selectedRule.type) {
                        case 'categorie':
                            if (this.categoriesToExclude && this.categoriesToExclude.length > 0) {
                                this.selectedRule.value = "";
                                for (let category of this.categoriesToExclude) {
                                    this.selectedRule.value += category.code + ";";
                                }
                            } else {
                                this.valueIsMissing = true;
                            }
                            break;
                        case 'produit':
                            if (this.productsToExclude && this.productsToExclude.length > 0) {
                                this.selectedRule.value = "";
                                for (let product of this.productsToExclude) {
                                    this.selectedRule.value += product.code + ";";
                                }
                            } else {
                                this.valueIsMissing = true;
                            }
                            break;
                        case 'fournisseur':
                            if (this.selectedSuppliers && this.selectedSuppliers.length > 0) {
                                this.selectedRule.value = "";
                                for (let supplier of this.selectedSuppliers) {
                                    this.selectedRule.value += supplier.code + ";";
                                }
                            } else {
                                this.valueIsMissing = true;
                            }
                            break;
                        case 'picto':
                            if (this.selectedPictos && this.selectedPictos.length > 0) {
                                this.selectedRule.value = "";
                                for (let supplier of this.selectedPictos) {
                                    this.selectedRule.value += supplier.code + ";";
                                }
                            } else {
                                this.valueIsMissing = true;
                            }
                            break;
                        case 'marque':
                            if (this.selectedBrands && this.selectedBrands.length > 0) {
                                this.selectedRule.value = "";
                                for (let brand of this.selectedBrands) {
                                    this.selectedRule.value += brand.label + ";";
                                }
                            } else {
                                this.valueIsMissing = true;
                            }
                            break;
                        case 'range':
                            if (this.rangesToExclude && this.rangesToExclude.length > 0) {
                                this.selectedRule.value = "";
                                for (let range of this.rangesToExclude) {
                                    this.selectedRule.value += range.minimumValue + "::" + range.maximumValue + ";";
                                }
                            } else {
                                this.valueIsMissing = true;
                            }
                            break;
                        default:
                            return;
                    }

                    //console.log(rule);

                    if (!this.valueIsMissing) {
                        this._commondata.showLoader(true);
                        let ruleListToPost = [];
                        for (let rl of this.selectedRule.ruleDtos) {
                            let ruleToPost = JSON.parse(JSON.stringify(this.selectedRule));
                            ruleToPost.enseigne = [rl.enseigneDto];
                            ruleToPost.active = rl.active;
                            let ruleToEnseigneToPost: RuleToEnseigneDtoInterface[] = [];
                            for (let ruleToEnseigne of ruleToPost.ruleToEnseigne) {
                                if (ruleToEnseigne.idEnseigne == rl.enseigneDto.id_ext_enseigne) {
                                    ruleToEnseigneToPost.push(ruleToEnseigne);
                                }
                            }
                            ruleToPost.ruleToEnseigne = ruleToEnseigneToPost;
                            ruleToPost.identityRule = this.identityRule;
                            console.log("valueRule : "+ruleToPost.identityRule);

                            ruleListToPost.push(ruleToPost);
                        }

                        this.dealWithStandardObservableSubscription(this.ruleService.post(ruleListToPost), {
                            success: response => {
                                if(this.selectedRule.idRule == null) {
                                    let httpParams = new HttpParams();
                                    this.searchRuleName = this.selectedRule.name;
                                    httpParams = httpParams.append("name", this.selectedRule.name);
                                    this.closeModal("editModal");
                                }
                                this._commondata.showLoader(false);
                                this.getRulesList();
                                this.identityRule = this.selectedRule.value+"##"+this.selectedRule.type;
                            },
                            error: response => {
                                console.log("error");
                                console.log(response);
                                this._commondata.showLoader(false);
                            }
                        });
                    }
                }
            },
            (error: any) => {
                console.log("error");
                console.log(error);
            }
        )
    }

    //endregion

    //region Rule forWho

    onSelectedEnseigne(enseigne: any) {

        let rule = {} as RuleDtoInterface;
        rule.enseigneDto = enseigne;
        rule.idRule = -1;
        rule.active = true;
        if (this.selectedRule.ruleDtos == null || this.selectedRule.ruleDtos == undefined || this.selectedRule.ruleDtos.length == 0) {
            this.selectedRule.ruleDtos = [];
        }
        this.selectedRule.ruleDtos.push(rule);

        this.getEnseigneProperties();
    }

    onDeSelectedEnseigne(enseigne: any) {
        this.enseignesProperties.delete(enseigne.nomEnseigne);
        this.selectedRule.ruleDtos = this.selectedRule.ruleDtos.filter(r => r.enseigneDto.id_ext_enseigne != enseigne.id_ext_enseigne);
        this.buildRuleToEnseigne();
    }

    OpenAccordion(sectionName: any, Wrapdiv: any) {

        var CurrentCls = document.getElementById(sectionName).getAttribute("class");
        if (CurrentCls == "acd-des") {
            document.getElementById(sectionName).setAttribute("class", "acd-des show");
            document.getElementById(Wrapdiv).setAttribute("class", "acd-group acd-active");
        } else {
            document.getElementById(sectionName).setAttribute("class", "acd-des");
            document.getElementById(Wrapdiv).setAttribute("class", "acd-group");
        }
    }

    getEnseigneProperties() {
        if (this.selectedRule.ruleDtos != null && this.selectedRule.ruleDtos.length > 0) {
            for (let rl of this.selectedRule.ruleDtos) {
                let ruleAndEnseigne = {} as RuleAndEnseigneDtoInterface;
                ruleAndEnseigne.idEnseigne = rl.enseigneDto.id_ext_enseigne;
                ruleAndEnseigne.idRule = rl.idRule;
                if (!this.selectedRule.idRule) {
                    ruleAndEnseigne.idRule = -1;
                }

                this.ruleService.getProperties(ruleAndEnseigne).subscribe(
                    (response) => {
                        this.enseignesProperties.set(rl.enseigneDto.nomEnseigne, response);
                        this.buildRuleToEnseigne();
                    },
                    (error) => {
                        console.log("error");
                        console.log(error);
                    }
                );
            }
        }
    }

    buildRuleToEnseigne() {
        this.selectedRule.ruleToEnseigne = [];
        this.enseignesProperties.forEach((propertyDtoEnseigne: PropertyDtoInterface[], key: string) => {
            let pushRuleToEnseigne: Boolean = true;
            this.showEnseigne.set(key, false);
            for (let propertyDto of propertyDtoEnseigne) {
                if (propertyDto.selectedPropertyValues != null && propertyDto.selectedPropertyValues.length > 0) {
                    this.showEnseigne.set(key, true);
                }
                if (propertyDto.selectedPropertyValues != null && propertyDto.selectedPropertyValues.length > 0) {
                    for (let value of propertyDto.selectedPropertyValues) {
                        if (value != null) {
                            const ruleToEnseigne = {} as RuleToEnseigneDtoInterface;
                            ruleToEnseigne.idPropriete = propertyDto.propertyId;
                            ruleToEnseigne.valeurPropriete = value.label;
                            ruleToEnseigne.idEnseigne = propertyDto.enseigneId;
                            let propertyValueDto = propertyDto.propertyValues.find(p => p.label != null && p.label == value.label);
                            if (propertyValueDto != null && propertyValueDto.value != null && propertyValueDto.value.trim() != '') {
                                ruleToEnseigne.valeurPropriete = propertyValueDto.value;
                            }
                            if (this.selectedRule.ruleToEnseigne == null) {
                                this.selectedRule.ruleToEnseigne = [];
                            }
                            this.selectedRule.ruleToEnseigne.push(ruleToEnseigne);
                            pushRuleToEnseigne = false;
                        }
                    }
                }
            }

            if (pushRuleToEnseigne) {
                const ruleToEnseigne = {} as RuleToEnseigneDtoInterface;
                this.selectedRule.ruleToEnseigne.push(ruleToEnseigne);
            }

        });
    }

    //endregion

    //region Rule type : Categories

    private loadCategories() {
        this.categories = null;
        this.categoryService.listTree().subscribe(response => {
            this.categories = CategorytreeUtils.generateTree(response, false);
            this.treeCatgeriesSelected = [];
            if (this.categoriesToExclude != null && this.categoriesToExclude != undefined && this.categoriesToExclude.length > 0) {
                for (let category of this.categoriesToExclude) {
                    this.treeCatgeriesSelected.push(CategorytreeUtils.findParent(category.idCategory, this.categories));
                }
            }
            //this.loadSelectedCategories();
            this.formGroupSearch = this.formBuilder.group({
                keywords: new UntypedFormControl(''),
                searchTypeCategory: new UntypedFormControl(''),
                searchMain: new UntypedFormControl(true),
                searchSpecific: new UntypedFormControl(false),
                searchService: new UntypedFormControl(false),
                searchAnimation: new UntypedFormControl(false),
                searchFilter: new UntypedFormControl('searchMain')
            });
            this.searchCriteriaCategories = {
                keywords: this.formGroupSearch.value.keywords,
                main: this.formGroupSearch.value.searchMain,
                specific: this.formGroupSearch.value.searchSpecific,
                service: this.formGroupSearch.value.searchService,
                animation: this.formGroupSearch.value.searchAnimation,
                type: this.formGroupSearch.value.searchTypeCategory != null && this.formGroupSearch.value.searchTypeCategory.length > 0 ? this.formGroupSearch.value.searchTypeCategory[0].item_id : null,
            };
            this.commondataService.showLoader(false);
        }, error => {
            console.log('Error : ' + JSON.stringify(error));
            this.commondataService.showLoader(false);
        });
    }

    private loadSelectedCategories(): void {
        this.categoriesToExclude = [];
        for (let category of this.selectedCategoriesInTree) {
            if (category.code) {
                this.categoriesToExclude.push(category);
            }
        }

        this.treeCatgeriesSelected = [];
        if (this.categoriesToExclude != null && this.categoriesToExclude != undefined) {
            for (let category of this.categoriesToExclude) {
                this.treeCatgeriesSelected.push(CategorytreeUtils.findParent(category.idCategory, this.categories));
            }
        }
    }

    searchCriteriaCategoryUpdate() {
        switch (this.formGroupSearch.value.searchFilter) {
            case "searchMain":
                this.formGroupSearch.value.searchMain = true;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchSpecific":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = true;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchService":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = true;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchAnimation":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = true;
                break;
        }
        this.searchCriteriaCategories = {
            keywords: this.formGroupSearch.value.keywords,
            main: this.formGroupSearch.value.searchMain,
            specific: this.formGroupSearch.value.searchSpecific,
            service: this.formGroupSearch.value.searchService,
            animation: this.formGroupSearch.value.searchAnimation,
            type: this.formGroupSearch.value.searchTypeCategory != null && this.formGroupSearch.value.searchTypeCategory.length > 0 ? this.formGroupSearch.value.searchTypeCategory[0].item_id : null,
        };
    }

    searchCategory(category: CategoryDtoInterface): void {
        this.syncCatalogService.syncSearchCategory(category);
    }

    categoryChange(): void {
        let productYoukadoCategoriesInTree = this.selectedCategoriesInTree
            .filter(item => CategorytreeUtils.findCategoryInTree(item.idCategory, this.categories));
        productYoukadoCategoriesInTree
            .filter(item => !this.selectedCategoriesInTree.includes(item.idCategory))
            .forEach(item => {
                this.selectedCategoriesInTree.splice(this.selectedCategoriesInTree
                    .findIndex(item2 => item2.idCategory === item.idCategory), 1);
            });
        (this.selectedCategoriesInTree as number[])
            .filter(item => !productYoukadoCategoriesInTree.map(item2 => item2.idCategory).includes(item))
            .forEach(item => {
                this.selectedCategoriesInTree.push(CategorytreeUtils.findCategoryInTree(item, this.categories));
            });
        this.loadSelectedCategories();
    }

    //endregion

    //region Rule type : Products

    checkProductToExclude() {
        this.codeAlreadyInListErrorMessage = false;
        this.wrongCodeErrorMessage = false;
        this.duplicatedCodes = [];
        this.undefinedCodes = [];

        this._commondata.showLoader(true);
        let regexProducts = new RegExp("[A-Za-z0-9]+", "g");
        let foundProducts = this.productToCheck.trim().match(regexProducts);
        for (let p of foundProducts) {
            if (this.productsToExclude.find(ps => ps.code == p)) {
                this.codeAlreadyInListErrorMessage = true;
                this.duplicatedCodes.push(p);
                this._commondata.showLoader(false);
            } else {
                this.productYoukadoService.getProductByCode({codeProduct: p}).subscribe(
                    (response) => {
                        if (this.productsToExclude.find(ps => ps.code == response.code)) {
                            this.codeAlreadyInListErrorMessage = true;
                            this.duplicatedCodes.push(p);
                            this._commondata.showLoader(false);
                        } else {
                            if (!response) {
                                this.wrongCodeErrorMessage = true;
                                this.undefinedCodes.push(p);
                            } else {
                                this.productsToExclude.push(response);
                            }
                            this._commondata.showLoader(false);
                        }
                    },
                    (error) => {
                        console.log("error");
                        console.log(error);
                        this._commondata.showLoader(false);
                    }
                )
            }
        }
    }

    public deleteFromProductsToExclude(index: number) {
        this.productsToExclude.splice(index, 1);
    }

    //endregion

    //region Rule type : Suppliers

    getAllSuppliers() {
        this._commondata.showLoader(true);
        this.supplierService.list().subscribe(
            (response) => {
                this.allSuppliers = response;
                this._commondata.showLoader(false);
            },
            (error) => {
                console.log("error");
                console.log(error);
                this._commondata.showLoader(false);
            });
    }

    //endregion

    //region Rule type : Brands

    getAllBrands() {

        this.brandService.listLite().subscribe(
            (response) => {
                this.allBrands = response;
                this._commondata.showLoader(false);
            },
            (error) => {
                console.log("error");
                console.log(error);
                this._commondata.showLoader(false);
            });
    }

    //endregion

    //region Rule type : Ranges

    checkRangeToExclude() {
        this.minMoreThanMax = false;

        // Angular 12 : 04/10/22 11h12
        // if you discard the "Math.abs", try this :
        // - minValue = a number between 11 and 20
        // - maxValue = 100
        // the if statement will return a false

        if (Math.abs(this.selectedValueMin) < Math.abs(this.selectedValueMax)) {
            this.rangesToExclude.push({
                minimumValue: this.selectedValueMin,
                maximumValue: this.selectedValueMax,
            });
            this.selectedValueMin = 0;
            this.selectedValueMax = 0;
        } else {
            this.minMoreThanMax = true;
        }
    }

    deleteFromRangesToExclude(index: number) {
        this.rangesToExclude.splice(index, 1);
    }

    //endregion

}
